<template>
  <div class="offcanvas__area">
    <div class="offcanvas__body">
      <div class="offcanvas__left">
        <div class="offcanvas__logo">
          <a href="index.html"><img src="@/assets/landing/assets/imgs/logo/site-logo-white-2.png"
              alt="Offcanvas Logo" /></a>
        </div>
      </div>
      <div class="offcanvas__mid">
        <div class="offcanvas__menu-wrapper">
          <nav class="offcanvas__menu">
            <ul class="menu-anim">
              <li>
                <a>home</a>
                <ul>
                  <li><a href="index.html">Digital Maketing</a></li>
                  <li><a href="index-dark.html">Digital Maketing dark</a></li>
                  <li><a href="index-2.html">Design Studio </a></li>
                </ul>
              </li>
              <li><a href="contact.html">contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="offcanvas__close">
        <button type="button" id="close_offcanvas">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Infrastructure"
};
</script>
