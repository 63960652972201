<template>
  <section class="hero__area-6 pb-140">
    <div class="container g-0 line_4">
      <div class="line-col-4">
        <div></div>
        <div></div>
        <div></div>
        <!-- <div></div> -->
      </div>

      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div class="hero__content-6 hero__six_anim">
            <img class="hero__arrow-icon" src="@/assets/landing/assets/imgs/hero/4/arrow-icon.png" alt="Arrow Icon" />
            <h1 class="hero__title-6">
              Sistem Informasi Air Tanah dan Air Baku
              <br />
              (Si-ATAB)
            </h1>
            <p>
              Sistem Informasi Air Tanah dan Air Baku Kementerian Pekerjaan Umum. Memuat informasi terkait data teknis
              infrastruktur Sumur Bor Air Tanah, Penampungan Air Hujan (PAH), Akuifer Buatan Simpanan Air Hujan (ABSAH),
              Mata Air, Intake Sungai, dsb.
            </p>
            <div class="btn-started btn-hover">Get Started</div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div class="hero__right-6">
            <img src="@/assets/images/pages/landing/hero-img.png" alt="Hero Image" />
          </div>
        </div>
      </div>
    </div>
    <img class="hero__shape" src="@/assets/landing/assets/imgs/hero/4/hero-shape.png" alt="Shape Image" />
    <img class="hero__shape-2" src="@/assets/landing/assets/imgs/hero/4/hero-shape-2.png" alt="Layout" />
    <img class="hero__shape-3" src="@/assets/landing/assets/imgs/hero/4/hero-shape-3.png" alt="Star Icon" />
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>
