<template>
  <header
    :class="{ 'header-border': true, scrolled: isScrolled }"
    class="header__area-3 header-border"
  >
    <div class="header__inner-3">
      <div class="header__logo-2">
        <router-link to="/">
          <img
            src="@/assets/images/logo/logo-v2.png"
            width="140px"
            alt="Site Logo"
            class
          />
        </router-link>
      </div>
      <div class="header__nav-2">
        <ul class="main-menu-3 menu-anim">
          <li class="has-megamenu"><a href="#">home</a></li>
          <li><a href="#infrastructure">Infrastuktur</a></li>
          <li></li>
        </ul>
      </div>
      <div class="header__nav-icon-3">
        <!-- <button id="open_offcanvas" @click="toggleParentOffCanvas">
          <img src="@/assets/landing/assets/imgs/icon/menu-black.png" alt="Menubar Icon" />
        </button> -->
        <a href="/home.index"
          ><button class="btn btn-primary btn-dashboard">Dashboard</button></a
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isScrolled: false,
    };
  },
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 200;
    },
  },
  toggleParentOffCanvas() {
    this.$emit("toggle-canvas", !this.status);
  },
};
</script>

<style>
.header-border {
  transition: border-bottom 0.3s ease;
}

.header-border.scrolled {
  border-bottom: 2px solid #c7c7c7;
}

.btn-dashboard {
  padding: 10px 25px 10px 25px;
}
</style>
