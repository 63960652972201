<template>
  <section id="infrastructure" class="pt-infrastructure pb-140">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
          <h2 class="sec-title">Infrastruktur.</h2>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
          <div class="sec-text">
            <p>
              Merupakan detail data rekap jumlah aset dan infrastruktur yang
              terdata di dalam Sistem Informasi Air Tanah dan Baku (SIATAB) yang
              mana mencakup seluruh Balai Wilayah Sungai / Balai Besar Wilayah
              Sungai
            </p>
          </div>
        </div>
      </div>
      <div class="row dashboard-cards" :class="{ showing: showing }">
        <div v-for="(data, index) in cards" :key="index" class="card"
          :class="{ 'd-card-show': activeCard === index }" :style="{ zIndex: activeCard === index ? zindex : 1 }"
          @click="toggleCard(index)">
          <div class="card-title">
            <h2>
              {{ data.title }}
              <!-- <small>{{ card.subtitle }}</small> -->
            </h2>
            <div class="task-count">{{ data.infraCount }}</div>
          </div>
          <div class="card-flap flap1">
            <div class="card-description">
              <ul class="task-list">
                <li v-for="(status, i) in data.status" :key="i">
                  {{ status.name }}
                  <span>{{ status.count }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";

export default {
  name: "Infrastructure",
  data() {
    return {
      totalIntakeSungai: 0,
      totalPahAbsah: 0,
      totalTampungan: 0,
      totalMataAir: 0,
      totalSumur: 0,
      sumurNonoperasi: 0,
      sumurNonaktif: 0,
      loading: true,
      error: null,
      cards: [],
      activeCard: null, // Indeks kartu yang aktif
      showing: false, // Status apakah ada kartu yang ditampilkan
      zindex: 10, // Nilai z-index
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axiosIns.get(
          "/v2/public/landing/infrastructure/total"
        );
        this.cards = response.data.data;
      } catch (error) {
        this.error = "Failed to load data: " + error.message;
        alert("error" + error.message);
      } finally {
        this.loading = false;
      }
    },
    toggleCard(index) {
      if (this.activeCard === index) {
        // Jika kartu yang diklik sudah aktif
        this.activeCard = null;
        this.showing = false;
      } else {
        // Jika kartu yang diklik belum aktif
        this.activeCard = index;
        this.showing = true;
        this.zindex++; // Tingkatkan z-index
      }
    },
  },
};
</script>

<style>
.pt-infrastructure {
  padding-top: 140px;
}

@media (min-width: 576px) {
  .pt-infrastructure {
    padding-top: 200px;
  }
}
</style>
